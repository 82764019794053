import React from 'react';
import { graphql } from 'gatsby';
import {
  Layout,
  AboutHeader,
  OurTeam,
  OurValues,
  DigitalProcess,
  OurClients,
  Testimonials,
  WorkWithUs,
  JoinOurNetwork,
} from 'components';

const AboutPage = ({
  data: {
    prismicAboutPage: {
      data: {
        header_image,
        heading,
        text,
        video,
        video_image,
        video_text,
        our_values_heading,
        our_values,
        work_with_us_heading,
        work_with_us_title,
        work_with_us_text,
        work_with_us_payne_bot_button_text,
        our_clients_heading,
        our_clients_text,
        our_clients,
        testimonials,
        join_our_network_button_text,
        join_our_network_heading,
        our_team_heading,
        our_team_members,
        process,
        our_digital_process_heading,
      },
    },
    prismicJobsPage: {
      data: { career_portal_apply_url },
    },
  },
}) => (
  <Layout>
    <AboutHeader
      headerImage={header_image.localFile}
      content={text.html}
      heading={heading.text}
      video={video}
      videoImage={video_image.localFile}
      videoText={video_text.html}
    />
    <OurTeam title={our_team_heading.text} team={our_team_members} />
    <OurValues title={our_values_heading.text} values={our_values} />
    <DigitalProcess
      title={our_digital_process_heading.text}
      process={process}
    />
    <WorkWithUs
      subtitle={work_with_us_title.text}
      title={work_with_us_heading.text}
      text={work_with_us_text.text}
      button_text={work_with_us_payne_bot_button_text}
    />
    <OurClients
      title={our_clients_heading.text}
      text={our_clients_text}
      clients={our_clients}
    />
    <Testimonials testimonials={testimonials} />
    <JoinOurNetwork
      title={join_our_network_heading.text}
      button_text={join_our_network_button_text}
      url={career_portal_apply_url.url}
    />
  </Layout>
);

export default AboutPage;

export const query = graphql`
  query {
    prismicAboutPage {
      data {
        header_image {
          localFile {
            childImageSharp {
              fluid(quality: 80, maxWidth: 1920) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        heading {
          text
        }
        text {
          html
        }
        video {
          url
        }
        video_image {
          localFile {
            childImageSharp {
              fluid(quality: 80, maxWidth: 1920) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        video_text {
          html
        }
        our_values_heading {
          text
        }
        our_values {
          value_text {
            text
          }
          value_heading {
            text
          }
        }
        work_with_us_heading {
          text
        }
        work_with_us_payne_bot_button_text
        work_with_us_text {
          text
        }
        work_with_us_title {
          text
        }
        our_clients_heading {
          text
        }
        our_clients_text
        our_clients {
          client_logo {
            localFile {
              childImageSharp {
                fixed(quality: 80, height: 100) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
        testimonials {
          testimonial_author
          testimonial_author_role_and_compnay
          testimonial_logo {
            localFile {
              childImageSharp {
                fluid(quality: 80, maxHeight: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          testimonial_text {
            text
          }
        }
        join_our_network_button_text
        join_our_network_heading {
          text
        }
        our_team_heading {
          text
        }
        our_team_members {
          member_image {
            localFile {
              childImageSharp {
                fluid(quality: 80, maxHeight: 586) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          member_title {
            text
          }
          member_type
          member_linked_in {
            url
          }
        }
        process {
          process_animation {
            url
          }
          process_heading {
            text
          }
          process_image {
            localFile {
              childImageSharp {
                fluid(quality: 80, maxWidth: 1024) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
          process_text {
            text
          }
        }
        our_digital_process_heading {
          text
        }
      }
    }
    prismicJobsPage {
      data {
        career_portal_apply_url {
          url
        }
      }
    }
  }
`;
